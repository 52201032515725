import { getCommonParamsOptions } from '@/utils/common'

export const baseFormConfig = {
  // colLayout: {
  //   span: 8
  // },
  formItems: [
    {
      field: 'cardNumber',
      type: 'input',
      label: 'card.card-no',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'cardName',
      type: 'input',
      label: 'card.card-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'cardType',
      type: 'select',
      label: 'common.app-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('card_type'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'status',
      type: 'select',
      label: 'common.app-status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('status'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'createdTime',
      type: 'input',
      label: 'common.app-creation-time',
      otherOptions: {
        disabled: true
      }
    }
  ]
}
