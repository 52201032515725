<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfig"
      :pageTitle="pageTitle"
      :pageInfo="pageInfo"
      pageType="view"
    ></page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/card.view.js'
import { useStore } from 'vuex'
import { ref } from 'vue'
import i18n from '@/i18n'
import { useRoute } from 'vue-router'

// 页面标题
const pageTitle = ref('')
const { t } = i18n.global
const routes = useRoute()

pageTitle.value = t('general.router-card-view')
const id = routes.params.id

const store = useStore()
const pageInfo = ref({})

const getCurrentPageInfo = async () => {
  // 获取当前页面的初始化值
  const cardInfo = await store.dispatch('param/getCardListByID', { id })
  cardInfo.tenantId = store.getters.tenantDataMap[cardInfo?.tenantId]
  pageInfo.value = cardInfo
}

getCurrentPageInfo()
</script>
